define("discourse/plugins/discourse-ebay-ads/discourse/components/ebay-ad-banner", ["exports", "@glimmer/component", "@glimmer/tracking", "discourse/lib/ajax", "discourse/lib/ajax-error", "@ember/object"], function (_exports, _component, _tracking, _ajax, _ajaxError, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class EbayAdBanner extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "model", [_tracking.tracked], function () {
      return null;
    }))();
    #model = (() => (dt7948.i(this, "model"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "voteStatus", [_tracking.tracked], function () {
      return 0;
    }))();
    #voteStatus = (() => (dt7948.i(this, "voteStatus"), void 0))();
    constructor() {
      super(...arguments);
      this.loadAd();
    }
    get formattedPrice() {
      if (this.model && this.model.price) {
        return "$" + parseFloat(this.model.price).toFixed(2);
      }
      return '?';
    }
    async loadAd() {
      try {
        const result = await (0, _ajax.ajax)("/ebay/ad");
        //console.log(result)

        this.model = result;
        let avatarUrl = this.model.seller_info.avatar;
        this.model.seller_info.avatar = avatarUrl.replace('{size}', '96');
        this.setupImpressionWatcher();
      } catch (error) {
        (0, _ajaxError.popupAjaxError)(error);
      }
    }
    trackEbayClick() {
      const encodedId = encodeURIComponent(this.model.item_id);
      let url = `/ebay/adclick/${encodedId}?banner=true`;
      (0, _ajax.ajax)(url).then(result => {}).catch(error => {
        console.error('Click not recorded:', error);
      });
    }
    static #_3 = (() => dt7948.n(this.prototype, "trackEbayClick", [_object.action]))();
    likeAd() {
      const vote = this.voteStatus == 1 ? 0 : 1;
      const encodedId = encodeURIComponent(this.model.item_id);
      let url = `/ebay/vote/${encodedId}?vote=${vote}`;
      (0, _ajax.ajax)(url).then(result => {
        this.voteStatus = vote;
      }).catch(error => {
        console.error('Vote not recorded:', error);
      });
    }
    static #_4 = (() => dt7948.n(this.prototype, "likeAd", [_object.action]))();
    dislikeAd() {
      const vote = this.voteStatus == -1 ? 0 : -1;
      const encodedId = encodeURIComponent(this.model.item_id);
      let url = `/ebay/vote/${encodedId}?vote=${vote}`;
      (0, _ajax.ajax)(url).then(result => {
        this.voteStatus = vote;
        console.log(this.voteStatus);
      }).catch(error => {
        console.error('Vote not recorded:', error);
      });
    }
    static #_5 = (() => dt7948.n(this.prototype, "dislikeAd", [_object.action]))();
    trackEbayImpression() {
      const encodedId = encodeURIComponent(this.model.item_id);
      let url = `/ebay/adimpression/${encodedId}?banner=true`;
      (0, _ajax.ajax)(url).then(result => {}).catch(error => {
        console.error('Impression not recorded:', error);
      });
    }
    setupImpressionWatcher() {
      let options = {
        root: null,
        rootMargin: '0px',
        threshold: 1.0
      };
      const observer = new IntersectionObserver(_ref => {
        let [entry] = _ref;
        if (entry.isIntersecting) {
          this.trackEbayImpression();
          observer.disconnect();
        }
      }, options);
      const impression = document.querySelector('.impression-observer');
      if (!impression) {
        console.error('Impression sentinel element not found.');
      } else {
        observer.observe(impression);
      }
    }
  }
  _exports.default = EbayAdBanner;
});